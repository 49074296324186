require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

function writeData(){
    let eData = document.querySelector('#edata');
    let eDataArr = ['be','josephadam.','@','contact'];
    let eDataOk = eDataArr.reverse().join("");
    eData.innerHTML=eDataOk;
    eData.setAttribute('href', 'mailto:'+eDataOk);

    let mData = document.querySelector('#mdata');
    mData.innerHTML='+32 4'+'84 '+'17'+' 42 '+'20';
    mData.setAttribute('href','te'+'l:'+'00324'+'84'+'17'+'42'+'20');
}
writeData();

/* function cleanAlt(){
    var imgs = document.getElementsByClassName("noaltval");
    for (var i = 0; i < imgs.length; i++) {
        imgs.item(i).setAttribute('alt','');
    }
} */
//cleanAlt();
